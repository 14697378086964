
@font-face {
  font-family: 'CustomFont';
  src: url('/public/sde.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


a {
  pointer-events: all;
  color: black;
  text-decoration: none;
}

svg {
  fill: black;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 5s ease 1s forwards;
}

.tit {
  color: white;
}

/* .carter-one-regular {
  font-family: "Carter One", system-ui;
  font-weight: 400;
  font-style: normal;
} */
 

/* Loading Screen Styles */


.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* This assumes you want it centered vertically in the viewport */
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10; /* Ensure it's above other elements */
}

.snapshots-title {
  font-family: 'CustomFont', Arial, sans-serif;
  font-size: clamp(2rem, 5vw, 4rem);
  color: #ffffff;
  text-align: center;
  letter-spacing: 3px;
  padding: 20px 10px;
  text-shadow: 
    0 0 5px rgba(255,255,255,0.5),
    0 0 10px rgba(255,255,255,0.3),
    0 0 15px rgba(255,255,255,0.2);
  transition: all 0.3s ease;
}

.snapshots-title:hover {
  text-shadow: 
    0 0 8px rgba(255,255,255,0.6),
    0 0 15px rgba(255,255,255,0.4),
    0 0 20px rgba(255,255,255,0.3);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .snapshots-title {
    font-size: clamp(1.5rem, 4vw, 3rem);
    letter-spacing: 2px;
  }
}

@media (max-width: 480px) {
  .snapshots-title {
    font-size: clamp(1.2rem, 3vw, 2.5rem);
    letter-spacing: 1px;
  }
}
.enterButton {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.enterButton:hover {
  background-color: #000;
  color: #fff;
}

.tiny5-regular {
  font-family: "Tiny5", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.press-start-2p-regular {
  font-family: "Press Start 2P", system-ui;
  font-weight: 400;
  font-style: normal;
}

