body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.app-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.canvas-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Loader styles */
.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("/public/assets/time.png") no-repeat center center fixed;
  background-size: cover;
  z-index: 1000;
}

.loader-content {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 80%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-ring {
  transform: rotate(-90deg);
  margin-bottom: 20px;
}

.progress-ring__circle,
.progress-ring__circle-progress {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.loader-text {
  color: white;
  margin-top: 10px;
  font-size: 16px;
}

.loader-time {
  color: white;
  margin-top: 5px;
  font-size: 24px;
  font-weight: bold;
}

/* Scroll instruction */
.scroll-instruction-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 1000;
}

.scroll-instruction {
  padding: 12px 24px;
  font-size: 18px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeInOut 5s ease-in-out, pulse 2s infinite;
  text-align: center;
  white-space: nowrap;
  scale: 3;
 margin: 0 auto;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 1; }
}

@keyframes pulse {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.05); }
}

.moon {
  font-family: 'CustomFont', Arial, sans-serif;
}

.toggle-button {
  position: absolute;
  z-index: 1;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 24px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 10px;
  color: #fff;
  background-color: #007BFF;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  animation: buttonPulse 2s infinite;
}

.toggle-button:hover {
  background-color: #0056b3;
  transform: translateX(-50%) scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

@keyframes buttonPulse {
  0%, 100% { transform: translateX(-50%) scale(1); }
  50% { transform: translateX(-50%) scale(1.05); }
}

/* Responsive Design */
@media (max-width: 768px) {
  .scroll-instruction {
    font-size: 16px;
    padding: 10px 20px;
    scale: 2.5;
  }
  .toggle-button {
    font-size: 16px;
    padding: 10px 20px;
  }
  .loader-content {
    width: 90%;
    max-width: 250px;
  }
}

@media (max-width: 480px) {
  .scroll-instruction {
    font-size: 14px;
    padding: 8px 16px;
    scale: 2;
  }
  .toggle-button {
    font-size: 14px;
    padding: 8px 16px;
    bottom: 20px;
  }
  .loader-content {
    width: 95%;
    max-width: 200px;
  }
  .loader-text {
    font-size: 14px;
  }
  .loader-time {
    font-size: 20px;
  }
}